@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

a:hover {
  text-decoration: none;
}

.sidebar {
  width: 220px;
  background-color: #222;
  transition: all 0.5s ease-in-out;
}
.bg-defoult {
  background-color: #222;
}
.sidebar ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.sidebar li a,
.sidebar li a.collapsed.active {
  display: block;
  padding: 8px 12px;
  color: #fff;
  border-left: 0px solid #dedede;
  text-decoration: none;
}
.sidebar li a.active {
  background-color: #000;
  border-left: 5px solid #dedede;
  transition: all 0.5s ease-in-out;
}
.sidebar li a:hover {
  background-color: #000 !important;
}
.sidebar li a i {
  padding-right: 5px;
}
.sidebar ul li .sub-menu li a {
  position: relative;
}
.sidebar ul li .sub-menu li a:before {
  font-family: FontAwesome;
  content: "\f105";
  display: inline-block;
  padding-left: 0px;
  padding-right: 10px;
  vertical-align: middle;
}
.sidebar ul li .sub-menu li a:hover:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 0;
  width: 5px;
  background-color: #111;
  height: 100%;
}
.sidebar ul li .sub-menu li a:hover {
  background-color: #222;
  padding-left: 20px;
  transition: all 0.5s ease-in-out;
}
.sub-menu {
  border-left: 5px solid #dedede;
}
.sidebar li a .nav-label,
.sidebar li a .nav-label + span {
  transition: all 0.5s ease-in-out;
}

.sidebar.fliph li a .nav-label,
.sidebar.fliph li a .nav-label + span {
  display: none;
  transition: all 0.5s ease-in-out;
}
.sidebar.fliph {
  width: 42px;
  transition: all 0.5s ease-in-out;
}

.sidebar.fliph li {
  position: relative;
}
.sidebar.fliph .sub-menu {
  position: absolute;
  left: 39px;
  top: 0;
  background-color: #222;
  width: 150px;
  z-index: 100;
}

.user-panel {
  clear: left;
  display: block;
  float: left;
}
.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}
.user-panel > .info,
.user-panel > .info > a {
  color: #fff;
}
.user-panel > .info > p {
  font-weight: 600;
  margin-bottom: 9px;
}
.user-panel {
  clear: left;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding: 25px 15px;
  border-bottom: 1px solid;
}
.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
}

.fliph .user-panel {
  display: none;
}
